import React from 'react';
import Head from 'next/head';

import useCurrentUrl from '@legacy/hooks/useCurrentUrl';

import { IOGMetadata } from './types';

const OGMetadata = ({ title, card, site, domain, description, image, url }: IOGMetadata): JSX.Element => {
  const currentUrl = useCurrentUrl();
  const pageUrl = url || currentUrl;
  return (
    <Head>
      <meta name="twitter:url" content={pageUrl} />
      <meta name="twitter:card" content={card} />
      <meta name="twitter:domain" content={domain} />
      <meta name="twitter:site" content={site} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image.url} />
    </Head>
  );
};

export default OGMetadata;
