// Libraries
import { breakpoints } from '@bitsoex/react-design-system';
import styled from 'styled-components';

// Helpers
import { fontFamilyTheme, H4 } from '@legacy/moon/particles/Typography/Typography';

const noBreakingSpace = `\\202F`;
const breakingSpace = `\\000A0`;

export const StyledCode = styled.code`
  div {
    ${fontFamilyTheme};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  @media screen and (min-width: ${breakpoints.tabletSmall}) {
    margin-bottom: 0;
    > div:last-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledCite = styled.cite`
  &::before {
    content: '${breakingSpace}—${noBreakingSpace}';
    display: inline-block;
  }
`;

export const StyledJob = styled.span`
  &::before {
    content: '${noBreakingSpace}-${noBreakingSpace}';
    display: inline-block;
  }
`;

export const StyledCompany = styled.span`
  &::before {
    content: ',${noBreakingSpace}';
    display: inline-block;
  }
`;

export const StyledFigure = styled.figure`
  display: flex;
  flex-direction: column-reverse;

  & > blockquote,
  & > figcaption {
    margin-bottom: 26px;
  }

  @media screen and (min-width: ${breakpoints.tabletSmall}) {
    margin-bottom: 39px;

    & + hr {
      margin-top: -3px;
      margin-bottom: 33px;
    }

    blockquote {
      margin-bottom: 0;
    }

    & > figcaption {
      margin-bottom: 12px;
    }
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    & > * {
      grid-column-start: 2;
      grid-column-end: -2;
      grid-row-start: auto;
      grid-row-end: auto;
    }

    & > figcaption {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`;

export const StyledFigureCaption = styled(H4).attrs({
  as: 'figcaption',
})`
  @media screen and (min-width: ${breakpoints.tabletSmall}) {
    font-size: 14px;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    font-size: 16px;
  }
`;
