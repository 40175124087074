import config from 'config';
import { useRouter } from 'next/router';
import { findLocale } from '@legacy/utils/locales';

export const useCurrentLocatedPath = (includeQuery = false, locale?: string): string => {
  const router = useRouter();
  const localePath = findLocale(locale || router.locale || '').pathPrefix;
  const rawPath = router.asPath;
  const fullPath = `${localePath}${rawPath}`;
  if (includeQuery === true) {
    return fullPath;
  }
  return fullPath.replace(/\?.*/, '');
};

export const usePageId = (): string =>
  useCurrentLocatedPath(true)
    .substring(1)
    .replace(/[^a-z-0-9]/g, '-');

const trailingSlash = /\/$/;

const useCurrentUrl = (locale?: string): string => {
  const locatedPath = useCurrentLocatedPath(false, locale).substring(1);
  return `${config.apiHost}${locatedPath}`.replace(trailingSlash, '');
};

export const useEncodedPath = (): string => {
  const url = window.location.href;
  const urlPathPattern = /^(?:(?:https?:)?\/\/)?(?:[^.]+\.){1,2}[^/]+(?<fullPath>.*)$/;
  const fullPath = url.replace(urlPathPattern, '$<fullPath>');
  return encodeURIComponent(fullPath);
};

export default useCurrentUrl;
