import { cloneElement } from 'react';
import dynamic from 'next/dynamic';
import { MSThemeProvider } from '@bitsoex/react-design-system';

import { Container } from '@legacy/moon/Layout';
import { useRegion } from '@hooks';

import { Wrapper, SectionContainer } from './BitsoCardLandingPage.styles';
import { isBrowser } from '@library/browser';

const CountryBanner = dynamic(async () => (await import('@src/components/CMS')).CountryBanner, {
  ssr: false,
});

type BitsoCardLandingPageProps = {
  cardHeroBanner: JSX.Element;
  cardFirstStepsList?: JSX.Element;
  cardSecondStepsList?: JSX.Element;
  cardDeals: JSX.Element;
  cardFeatures?: JSX.Element;
  cardFAQ: JSX.Element;
  banner: JSX.Element;
  cardBottomBanner: JSX.Element;
};

const BitsoCardLandingPage = (props: BitsoCardLandingPageProps): JSX.Element => {
  const region = useRegion();
  const cardDealsBackground = props?.cardDeals?.props?.props?.backgroundImage?.url;

  return (
    <MSThemeProvider mode="dark">
      {/* @ts-ignore */}
      {region && region !== 'MX' && isBrowser() && <CountryBanner />}
      <Wrapper>
        <SectionContainer $gradients={1}>
          <Container width="auto">{props.cardHeroBanner}</Container>
        </SectionContainer>
        <SectionContainer>
          <Container width="auto">{props.cardFirstStepsList ? (
            cloneElement(props.cardFirstStepsList, { props: { ...props.cardFirstStepsList.props.props, isFirstStepsSection: true } })
          ) : null}</Container>
        </SectionContainer>
        <SectionContainer $style={{
          backgroundImage: `url(${cardDealsBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
        }}>
          <Container width="auto" margin="auto">{props.cardDeals}</Container>
        </SectionContainer>
        <SectionContainer
          $style={{
            backgroundImage: 'url("/images/cardLandingThirdSection.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          <Container width="auto">{props.cardSecondStepsList}</Container>
        </SectionContainer>
        <SectionContainer>
          <Container width="auto">{props.cardFeatures}</Container>
        </SectionContainer>
        <SectionContainer $gradients={1}>
          <Container width="auto">
            {cloneElement(props.cardFAQ, { props: { ...props.cardFAQ.props.props, mode: 'dark' } })}
          </Container>
        </SectionContainer>
        {/* <SectionContainer $gradients={2} $style={{ position: 'relative' }}>
          <Container width="auto">{props.cardBottomBanner}</Container>
        </SectionContainer> */}
      </Wrapper>
    </MSThemeProvider>
  );
};

export default BitsoCardLandingPage;
