// Libraries
import styled, { css } from 'styled-components';
import { breakpoints, universe } from '@bitsoex/react-design-system';

// Helpers
import { NumberedList, List, BodyBase, BodyBaseStyles } from '@legacy/moon/particles/Typography/Typography';

export const StyledParagraph = styled(BodyBase).attrs({
  as: 'div',
})`
  color: ${universe.moonlight60};
  margin-bottom: 26px;

  @media screen and (min-width: ${breakpoints.tabletSmall}) {
    margin-bottom: 39px;

    & + hr {
      margin-top: -3px;
      margin-bottom: 33px;
    }
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin-bottom: 40px;

    & + hr {
      margin-top: -5px;
      margin-bottom: 35px;
    }
  }
`;

const commonListStyles = css`
  margin-bottom: 26px;
  color: ${universe.moonlight60};

  & > li {
    align-items: flex-start;
    color: inherit;

    &::before {
      ${BodyBaseStyles} padding-bottom: 0;
      color: inherit;
    }
  }
`;

export const StyledNumberedList = styled(NumberedList)`
  ${commonListStyles} & > li {
    &::before {
      content: counter(ordered-counter) '.';
    }
  }

  ${StyledParagraph} {
    margin-bottom: 7px;
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    &,
    & > li {
      padding-left: 0;
    }
  }
`;

export const StyledList = styled(List)`
  ${commonListStyles} & > li {
    &::before {
      margin: 11px 8px;
      background-color: ${universe.moonlight60};
      border-radius: 3px;
      flex: 0 0 6px;
      width: 6px;
      height: 6px;
    }
  }

  ${StyledParagraph} {
    margin-bottom: 0;
  }
`;

export const StyledHr = styled.hr`
  margin: 26px 0;
  border-top: 1px solid ${universe.borealis60};

  @media screen and (min-width: ${breakpoints.tabletSmall}) {
    margin: 36px 0;
  }
`;
