import React from 'react';
import { spacing } from '@bitsoex/react-design-system/foundations';

import { IPage } from './types';
import CtfImage from '../CtfImage';
import { isBrowser } from '@library/browser';

const PressroomPage = ({ children, content, contentTable, coverImage }: IPage): JSX.Element => {
  return (
    <>
      {children}
      {isBrowser() && <CtfImage {...coverImage} priority />}
      <hr style={{ marginTop: spacing.size03 }} />
      {contentTable}
      <hr />
      {content}
    </>
  );
};

export default PressroomPage;
