import React from 'react';

import { IPage } from './types';

const Page = ({ children, content }: IPage): JSX.Element => (
  <>
    {children}
    {content}
  </>
);

export default Page;
