import React from 'react';

import { GenericLandingPageProps } from './types';
import { StyledGenericLandingPage, StyledGenericLandingPageWrapper } from './styles';

const GenericLandingPage = (props: GenericLandingPageProps): JSX.Element => (
  <StyledGenericLandingPageWrapper>
    <StyledGenericLandingPage>
      {props.heroSection}
      {props.featureSection}
      {props.stepsListSection}
      {props.hubSpotForm}
      {props.contactForm}
      {props.faqSection}
      {props.finalCTA}
    </StyledGenericLandingPage>
  </StyledGenericLandingPageWrapper>
);

export default GenericLandingPage;
