export const periodOptions = {
  us: [
    { value: '1D', label: '1 Day' },
    { value: '1W', label: '1 Week' },
    { value: '1M', label: '1 Month' },
    { value: '3M', label: '3 Months' },
    { value: '1Y', label: '1 Year' },
  ],
  br: [
    { value: '1D', label: '1 Dia' },
    { value: '1W', label: '1 Semana' },
    { value: '1M', label: '1 Mês' },
    { value: '3M', label: '3 Meses' },
    { value: '1Y', label: '1 Ano' },
  ],
  mx: [
    { value: '1D', label: '1 Día' },
    { value: '1W', label: '1 Semana' },
    { value: '1M', label: '1 Mes' },
    { value: '3M', label: '3 Meses' },
    { value: '1Y', label: '1 Año' },
  ],
  ar: [
    { value: '1D', label: '1 Día' },
    { value: '1W', label: '1 Semana' },
    { value: '1M', label: '1 Mes' },
    { value: '3M', label: '3 Meses' },
    { value: '1Y', label: '1 Año' },
  ],
  co: [
    { value: '1D', label: '1 Día' },
    { value: '1W', label: '1 Semana' },
    { value: '1M', label: '1 Mes' },
    { value: '3M', label: '3 Meses' },
    { value: '1Y', label: '1 Año' },
  ],
};

export const formatDate = (date: string, countryCode: string): string => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as const;
  const formattedDate = new Date(date).toLocaleString(countryCode, options);

  return formattedDate;
};
