import React from 'react';
import { StyledIcon, StyledWarning } from './styles';

const DevWarning = ({ children, isError = false }: { children: React.ReactNode; isError?: boolean }): JSX.Element => {
  if (
    !process.env.NEXT_PUBLIC_CONTENTFUL_ALLOW_PREVIEW_MODE ||
    !process.env.NEXT_PUBLIC_CONTENTFUL_ALLOW_PREVIEW_MODE.match(/^true$/i)
  ) {
    return <></>;
  }
  return (
    <StyledWarning color={isError ? 'red' : 'orange'}>
      <StyledIcon icon="warning" />
      {children}
    </StyledWarning>
  );
};

export default DevWarning;
