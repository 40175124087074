import { breakpoints } from '@bitsoex/react-design-system';
import styled from 'styled-components';

export const StyledDeals = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.tabletLarge}) {
    flex-direction: row;
    height: 509px;
  }

  > div {
    flex: 0 0 50%;

    &:first-of-type {
      padding: 20px;

      @media only screen and (min-width: ${breakpoints.tabletLarge}) {
        padding: 0 20px 0 112px;
      }
    }
  }

  button {
    width: 100%;
    margin: auto;

    @media only screen and (min-width: ${breakpoints.tabletSmall}) {
      max-width: 280px;
      margin: unset;
    }
  }
`;

export const StyledImage = styled.div`
  > div {
    width: 320px;

    @media only screen and (min-width: ${breakpoints.tabletLarge}) {
      width: 100%;
    }
  }
`;
