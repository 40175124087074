import { useEffect, useState } from 'react';
import Script from 'next/script';
import uuid from 'uuid/v1';
import CtfImage from '@vendors/contentful/cms/renderers/CtfImage';
import { imageCustomProps } from '@src/components/CMS/ContactForm/ContactForm.utils';
import { StyledHeading, StyledContactFormSection, StyledImage } from './HubSpotForm.styles';

const HubSpotForm = ({ hubSpotScript, formTitle, image }): JSX.Element => {
  const scriptTag = `<Script>${hubSpotScript}</Script>`;
  const [form, setForm] = useState<string>('');
  const [scriptId, setScriptId] = useState<string>('123');

  useEffect(() => {
    setScriptId(uuid());
    setForm(hubSpotScript);
  }, [hubSpotScript]);

  return form ? (
    <StyledContactFormSection withImage={Boolean(image)} id="hubSpot-form">
      <Script id={scriptId} onLoad={() => eval(form)} type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js" />
      <div>
        <StyledHeading>{formTitle}</StyledHeading>
        <div id={scriptId} dangerouslySetInnerHTML={{ __html: scriptTag }} />
      </div>
      {image ? (
        <StyledImage>
          <CtfImage {...image} customProps={imageCustomProps} />
        </StyledImage>
      ) : null}
    </StyledContactFormSection>
  ) : (
    <></>
  );
};

export default HubSpotForm;
