import React from 'react';
import Head from 'next/head';

import { SmartbannerMetadata } from './types';

const Smartbanner = ({
  title,
  author,
  tagPrice,
  googleIcon,
  button,
  buttonUrlGoogle,
  disablePositioning,
  enabledPlatforms,
  priceSuffixGoogle,
}: SmartbannerMetadata): JSX.Element => (
  <Head>
    <meta property="smartbanner:title" content={title} />
    <meta property="smartbanner:author" content={author} />
    <meta property="smartbanner\:price" content={tagPrice} />
    <meta property="smartbanner\:price-suffix-google" content={priceSuffixGoogle} />
    <meta property="smartbanner:icon-google" content={googleIcon.url} />
    <meta property="smartbanner:button" content={button} />
    <meta property="smartbanner:button-url-google" content={buttonUrlGoogle} />
    <meta property="smartbanner:enabled-platforms" content={enabledPlatforms} />
    <meta property="smartbanner:disable-positioning" content={disablePositioning} />
  </Head>
);

export default Smartbanner;
