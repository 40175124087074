import React from 'react';

import { Link } from '@legacy/moon/particles/Typography/Typography';
import { IBlockquoteCaption } from './types';
import { StyledCite, StyledJob, StyledCompany, StyledFigureCaption } from './styles';

const CiteText = ({ citeText, citeUrl }: Omit<IBlockquoteCaption, 'author' | 'jobTitle' | 'company'>): JSX.Element => {
  const Cite = (): JSX.Element => (
    <span>
      <StyledCite>
        {citeUrl ? (
          <Link target="_blank" href={citeUrl}>
            {citeText}
          </Link>
        ) : (
          <>{citeText}</>
        )}
      </StyledCite>
    </span>
  );

  if (!citeText) {
    return <></>;
  }
  return <Cite />;
};

const BlockquoteCaption = ({ author, jobTitle, company, ...citeProps }: IBlockquoteCaption): JSX.Element => {
  const JobTitle = (): JSX.Element => (jobTitle ? <StyledJob>{jobTitle}</StyledJob> : <></>);
  const Company = (): JSX.Element => (company ? <StyledCompany>{company}</StyledCompany> : <></>);
  return (
    <StyledFigureCaption>
      <span>
        {author}
        <JobTitle />
        <Company />
      </span>
      <CiteText {...citeProps} />
    </StyledFigureCaption>
  );
};

export default BlockquoteCaption;
