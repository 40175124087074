import styled from 'styled-components';

import { Paragraph } from '@legacy/moon/particles/Typography';

export const StyledLegalPage = styled.section`
  margin: 80px auto 0;
  max-width: 1140px;
  padding: 20px 10px;

  ol {
    list-style: upper-roman;

    li {
      padding-left: 0;
    }

    > li {
      margin-bottom: 20px;
    }

    ol {
      list-style: lower-alpha;
    }
  }

  ol > li > div {
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 0;
  }

  h1 {
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    margin: 20px auto;
    text-align: center;
  }

  h3 {
    line-height: 24px;
  }

  div {
    font-weight: 300;
  }
`;

export const StyledTitle = styled.h1`
  margin-top: 80px;
  text-align: center;
`;

export const StyledDate = styled(Paragraph)`
  font-weight: 300;
  margin-bottom: 15px;
  text-align: right;
`;
