import React, { useCallback } from 'react';

import { GenericHeroBanner, FinalCTA, FAQSection, GenericStepsList, Heading, LinkCTA } from '@src/components/CMS';
import HubSpotForm from '@src/components/CMS/HubSpotForm';

import GenericLandingPage from '../GenericLandingPage';
import LegalPageRenderer from '../LegalPageRenderer';
import BitsoCardLandingPage from '../BitsoCardLandingPage';

import Page from '../Page';
import Seo from '../Seo';
import OGMetadata from '../OGMetadata';
import TwitterMetadata from '../TwitterMetadata';
import SmartBanner from '../Smartbanner';
import { CMSPropParams, ICmsEntry } from './types';
import CtfRichText from '../CtfRichText';
import { renderers as blockQuoteRenderers } from '../Blockquote';
import CardDeals from '@src/components/CMS/CardDeals';
import CardHeroBanner from '@src/components/CMS/HeroBanner';
import CardStepsList from '@src/components/CMS/StepsList';
import FeatureSection from '@src/components/CMS/FeatureSection/FeatureSection';
import ContactForm from '@src/components/CMS/ContactForm/ContactForm';
import CardFeatures from '@src/components/CMS/Features';
import CardBottomBanner from '@src/components/CMS/Banner/Banner';
import PressroomPage from '../PressroomPage';
import PressroomContentTable from '@components/CMS/PressroomContentTable';

const renderers = {
  page: Page,
  pagePricesPage: Page,
  pagePricesPagePageType: Page,
  seo: Seo,
  ogMetadata: OGMetadata,
  twitterMetadata: TwitterMetadata,
  googlePlayMetadata: SmartBanner,
  heading: Heading,
  linkCta: LinkCTA,
  genericLandingPage: GenericLandingPage,
  heroBannerSection: GenericHeroBanner,
  stepsListSection: GenericStepsList,
  cardHeroBanner: CardHeroBanner,
  cardFirstStepsList: CardStepsList,
  cardSecondStepsList: CardStepsList,
  cardFeatures: CardFeatures,
  contactForm: ContactForm,
  cardBottomBanner: CardBottomBanner,
  cardDeals: CardDeals,
  legalPage: LegalPageRenderer,
  pageTrendingReportPage: Page,
  pressRoomPage: PressroomPage,
  pressRoomContentTable: PressroomContentTable,
  featureSection: FeatureSection,
  finalCTA: FinalCTA,
  faqSection: FAQSection,
  bitsoCardLandingPage: BitsoCardLandingPage,
  hubSpotForm: HubSpotForm,
  ...blockQuoteRenderers,
  ...CtfRichText,
} as const;

const cmsProp = ({ prop, propName }: Omit<CMSPropParams, 'propIndex'>) => {
  if (Array.isArray(prop))
    return prop.map((propItem, index) => {
      const itemProps = { prop: propItem, propName, propIndex: index };

      if (propName === 'children') return cmsProp({ ...itemProps });

      return cmsProp(itemProps);
    });

  if (!prop?.__metadata?.componentId) return prop;

  return cmsEntry({ ...prop });
};

export const cmsEntry = <Data = unknown,>({ props }: ICmsEntry): Record<string, Data> => {
  const componentProps = {} as Record<string, Data>;

  Object.entries(props).forEach(([propName, prop]) => {
    componentProps[propName] = cmsProp({ prop: prop as ICmsEntry, propName });
  });

  return componentProps;
};

const CMSProp = ({ prop, propName, propIndex }: CMSPropParams) => {
  if (Array.isArray(prop))
    return prop.map((propItem, index) => {
      const itemProps = { prop: propItem, propName, propIndex: index };

      if (propName !== 'children') return CMSProp(itemProps);

      return <CMSProp key={`${propName}--${index}`} {...itemProps} />;
    });

  if (!prop?.__metadata?.componentId) return prop;

  const { componentId } = prop.__metadata;

  const isCustomRenderer = componentId in renderers && propName in renderers && componentId !== propName;

  if (isCustomRenderer) prop.__metadata.componentId = propName;

  return <CMSEntry key={`${propName}-${propIndex}-${prop.__metadata.id}`} {...prop} />;
};

const CMSEntry = ({ __metadata: meta, props, children }: ICmsEntry): JSX.Element => {
  const { componentId } = meta;

  const renderProps = useCallback(() => {
    const newProps = {} as ICmsEntry;
    Object.entries(props).forEach(([propName, prop], propIndex) => {
      newProps[propName] = CMSProp({ prop: prop as ICmsEntry, propName, propIndex });
    });
    return newProps;
  }, [props]);

  const Component = renderers[componentId as keyof typeof renderers];

  if (!Component) return <></>; // @TODO: we will improve in the future

  return (
    <>
      {/* @ts-ignore */}
      <Component {...renderProps()} locale={meta.locale} />
      {children}
    </>
  );
};

export default CMSEntry;
