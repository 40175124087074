// Libraries
import styled from 'styled-components';
import { rgba } from 'polished';
import { typography, universe, breakpoints } from '@bitsoex/react-design-system';

export const StyledHeading = styled.h2`
  ${typography.titleMedium};
  margin-bottom: 16px;

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    ${typography.titleBase};
  }

  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    ${typography.titleMedium};
  }
`;

export const StyledImage = styled.div`
  display: none;

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    position: relative;
    display: block;

    > div {
      background: ${universe.earth10};
      border-radius: 16px;
    }
  }
`;

export const StyledContactFormSection = styled.section<{ withImage: boolean }>`
  border-radius: 12px;
  box-shadow: 0 4px 8px ${rgba(universe.moonlight40, 0.3)};
  margin: 0 16px 45px;
  padding: 12px 12px 16px;

  @media only screen and (min-width: ${breakpoints.tabletSmall}) {
    margin: 0 24px 33px;
    padding: 40px 27px 35px;
  }

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 16px 58px;
    margin: ${props => (props.withImage ? '0 16px 58px' : '0 auto 58px')};
    max-width: ${props => (props.withImage ? '100%' : '550px')};

    > div:first-of-type {
      flex: 1;
      min-height: 550px;
      max-width: 550px;
      padding-right: 35px;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    box-shadow: 0 4px 8px ${rgba(universe.moonlight40, 0.3)};
    margin: ${props => (props.withImage ? '45px 0' : '45px auto')};
    max-width: ${props => (props.withImage ? '100%' : '550px')};
    padding: 25px 24px 40px 37px;
  }
`;
