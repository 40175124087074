import React from 'react';
import { Link, ListItem } from '@legacy/moon/particles/Typography/Typography';
import { IGenericContainer, IHyperlink } from './types';
import { IMappedAsset } from '../../types';
import { StyledList, StyledParagraph, StyledHr } from './styles';
import DevWarning from '../ComponentNotFound/DevWarning';
import CtfImage from '../CtfImage';
import { ICtfImage } from '../CtfImage/types';

const CtfRichTextImage = (props: ICtfImage): JSX.Element => (
  <CtfImage
    {...props}
    customProps={{
      ...(props.customProps || {}),
      default: {
        border: 8,
        ...((props.customProps && props.customProps.default) || {}),
      },
    }}
  />
);

const CtfRichText = ({ children }: IGenericContainer): JSX.Element => <>{children}</>;

const CtfParagraph = ({ children }: IGenericContainer): JSX.Element => <StyledParagraph>{children}</StyledParagraph>;

const CtfBold = ({ children }: IGenericContainer): JSX.Element => <b>{children}</b>;
const CtfItalic = ({ children }: IGenericContainer): JSX.Element => <i>{children}</i>;
const CtfUnderline = ({ children }: IGenericContainer): JSX.Element => <u>{children}</u>;
const CtfCode = ({ children }: IGenericContainer): JSX.Element => <code>{children}</code>;
const CtfHyperlink = ({ children, data: { uri, target } }: IHyperlink): JSX.Element => (
  <Link target={target || undefined} href={uri}>
    {children}
  </Link>
);

const CtfOrderedList = ({ children }: IGenericContainer): JSX.Element => <ol>{children}</ol>;
const CtfUnorderedList = ({ children }: IGenericContainer): JSX.Element => <StyledList>{children}</StyledList>;
const CtfListItem = ({ children }: IGenericContainer): JSX.Element => <ListItem>{children}</ListItem>;

const InlineAsset = ({ asset: { url, title, description } }: { asset: IMappedAsset }): JSX.Element => (
  <img title={title} alt={description} src={url} />
);

const CtfHr = (): JSX.Element => <StyledHr />;

const CtfBlockquote = ({ children }: IGenericContainer): JSX.Element => (
  <>
    <DevWarning>
      {` `} Use an embedded Blockquote entry instead of the Contentful
      {`'`}s built in blockquote. Embedded Blockquote entries are better made for SEO{' '}
    </DevWarning>
    <blockquote>{children}</blockquote>
  </>
);

const CtfMissingLinkTo = ({ modelId }: { modelId: string }): JSX.Element => (
  <DevWarning isError>
    Need to add a correct {'"'}
    <CtfCode>getLinkToEntry</CtfCode>
    {'"'} function in the {modelId} mapper
  </DevWarning>
);

const richTextRenderers = {
  ctfRichText: CtfRichText,
  ctfParagraph: CtfParagraph,
  ctfBold: CtfBold,
  ctfItalic: CtfItalic,
  ctfUnderline: CtfUnderline,
  ctfCode: CtfCode,
  ctfHyperlink: CtfHyperlink,
  'ctfOrdered-list': CtfOrderedList,
  'ctfUnordered-list': CtfUnorderedList,
  'ctfList-item': CtfListItem,
  ctfImage: CtfRichTextImage,
  ctfHr: CtfHr,
  ctfBlockquote: CtfBlockquote,
  inlineAsset: InlineAsset,
  ctfMissingLinkTo: CtfMissingLinkTo,
};

export default richTextRenderers;
