import styled from 'styled-components';

import Icon from '@migrated/components/Icon';

export const StyledWarning = styled.div`
  font-size: 24px;
  color: ${({ color }) => color};
  padding: 15px 10px;
  border: 1px ${({ color }) => color} dashed;
`;

export const StyledIcon = styled(Icon)`
  font-size: 24px;
`;
