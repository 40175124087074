import React from 'react';
import Head from 'next/head';

import useCurrentUrl from '@legacy/hooks/useCurrentUrl';

import { IOGMetadata } from './types';

const OGMetadata = ({ title, type, description, image, siteName, url }: IOGMetadata): JSX.Element => {
  const currentUrl = useCurrentUrl();
  const pageUrl = url || currentUrl;
  return (
    <Head>
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content={type} />
      <meta property="og:image:type" content={image.contentType} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" itemProp="image" content={image.url} />
    </Head>
  );
};

export default OGMetadata;
