import * as React from 'react';
import styled from 'styled-components';

const COLUMN_SIZES = ['xs', 'sm', 'md', 'lg', 'xl', 'tp', 'tl', 'sd', 'sw'] as const;

/**
 *  This styled component aims to enables a simple way to pass props as styling ones, same as 'style' prop but applying it to the styled-component class construction
 */
export const FullStyled = styled.div<{ $style?: React.CSSProperties }>(props => ({ ...(props?.$style || {}) }));

export type ColumnsType = { [key in typeof COLUMN_SIZES[number]]?: number } | number;

export const getClassName = (columns?: ColumnsType, className?: string): string => {
  const customClassname = className || '';
  let customColumns = [''];

  if (columns) {
    customColumns =
      typeof columns === 'object'
        ? (Object.keys(columns) as unknown as typeof COLUMN_SIZES[number][]).map(size => `col-${size}-${columns[size]}`)
        : COLUMN_SIZES.map(size => `col-${size}-${String(columns)}`);
  }

  return [...customColumns, customClassname].join(' ').trim();
};

const StyledContainer = styled.div<{ columns?: ColumnsType; width?: string } & ContainerProps>`
  ${({ columns, width }) => !columns && `width: ${width}`};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  margin-left: ${({ center }) => center && 'auto !important'};
  margin-right: ${({ center }) => center && 'auto !important'};
  max-width: 1280px;
`;
export interface ContainerProps {
  children?: React.ReactNode;
  width?: string;
  columns?: ColumnsType;
  height?: string;
  padding?: string;
  margin?: string;
  center?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Container = (props: ContainerProps): JSX.Element => {
  const { columns, className, children } = props;

  return (
    <StyledContainer {...props} className={getClassName(columns, className)}>
      {children}
    </StyledContainer>
  );
};

Container.defaultProps = {
  width: undefined,
  columns: undefined,
  height: 'auto',
  padding: '0',
  className: '',
  margin: 'inherit',
  children: undefined,
  center: false,
};

export default Container;
