export interface ILocale {
  country: string;
  pathPrefix: string;
  withCountryCode: string;
  language: string;
  hrefLang: string;
  countryName: string;
}

export const locales: ILocale[] = [
  {
    country: 'us',
    pathPrefix: '',
    withCountryCode: 'en-US',
    hrefLang: 'en',
    language: 'en',
    countryName: 'United States',
  },
  {
    country: 'mx',
    pathPrefix: '/mx',
    withCountryCode: 'es-MX',
    hrefLang: 'es-mx',
    language: 'es',
    countryName: 'México',
  },
  {
    country: 'ar',
    pathPrefix: '/ar',
    withCountryCode: 'es-AR',
    hrefLang: 'es-ar',
    language: 'es',
    countryName: 'Argentina',
  },
  {
    country: 'br',
    pathPrefix: '/br',
    withCountryCode: 'pt-BR',
    hrefLang: 'pt-br',
    language: 'pt',
    countryName: 'Brasil',
  },
  {
    country: 'co',
    pathPrefix: '/co',
    withCountryCode: 'es-CO',
    hrefLang: 'es-co',
    language: 'es',
    countryName: 'Colombia',
  },
];

export const countryCodes: string[] = Array.from(new Set(locales.map(({ withCountryCode }) => withCountryCode)).keys());

export function findLocale(search: string): ILocale {
  const value = search.toLowerCase().replace(/^[^-]+-/, '');
  return (
    locales.find(
      locale => locale.country.toLocaleLowerCase() === value || locale.pathPrefix.toLocaleLowerCase() === value
    ) || defaultLocale
  );
}

export const defaultLocale = locales[0];
