import { IRobots } from './types';

const getRobotsValue = (robotProps: IRobots): string => {
  let content = '';
  if (robotProps.noIndex && robotProps.noFollow) {
    content = 'none';
  } else if (robotProps.noIndex) {
    content = 'noindex,follow';
  } else if (robotProps.noFollow) {
    content = 'index,nofollow';
  } else {
    content = 'index,follow';
  }
  if (robotProps.noImageIndex) {
    content += ',noimageindex';
  }
  if (robotProps.noCache) {
    content += ',noarchive,nocache';
  }
  if (robotProps.noSnippet) {
    content += ',nosnippet';
  }
  if (robotProps.unavailableAfter) {
    content += `,unavailable_after: ${robotProps.unavailableAfter}`;
  }
  return content;
};

export default getRobotsValue;
