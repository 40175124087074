import React from 'react';

import BlockquoteCaption from './BlockquoteCaption';
import { StyledCode, StyledFigure } from './styles';
import { ICtfBlockquote } from './types';

const CtfBlockquote = ({ text, caption, url }: ICtfBlockquote): JSX.Element => (
  <StyledFigure>
    <blockquote cite={url}>
      <StyledCode>{text}</StyledCode>
    </blockquote>
    {caption}
  </StyledFigure>
);

export default CtfBlockquote;

export const renderers = {
  blockquote: CtfBlockquote,
  blockquoteCaption: BlockquoteCaption,
};
