import React from 'react';
import Head from 'next/head';

import useCurrentUrl from '@legacy/hooks/useCurrentUrl';
import { useLanguageContext } from '@migrated/context/LanguageContext';

import { ISeo } from './types';
import getRobotsValue from './robots';

const Seo = ({ title, description, children: seoMetadataMotors, favicon, keywords, ...props }: ISeo): JSX.Element => {
  const { language } = useLanguageContext();
  const pageUrl = useCurrentUrl(language.data);

  const robotsValue = getRobotsValue(props);
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={description} />
        <meta name="robots" content={robotsValue} />
        {keywords ? <meta name="keywords" content={keywords.join(', ')} /> : null}

        <link rel="canonical" href={pageUrl} />

        <title>{title}</title>
      </Head>
      {!favicon ? null : (
        <>
          <link rel="icon" type="image/png" sizes="16x16" href={`${favicon.url}?w=16&h=16&fit=fill`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`${favicon.url}?w=32&h=32&fit=fill`} />
          <link rel="icon" type="image/png" sizes="96x96" href={`${favicon.url}?w=96&h=96&fit=fill`} />
        </>
      )}
      {seoMetadataMotors}
    </>
  );
};

export default Seo;
