import * as React from 'react';
import styled from 'styled-components';
import Container, { ContainerProps } from './Container';

const StyledFlex = styled(Container)<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-wrap: ${({ flexWrap }) => flexWrap};
`;

export interface FlexContainerProps extends ContainerProps {
  direction?: string;
  alignItems?: string;
  justifyContent?: string;
  flexWrap?: string;
  style?: React.CSSProperties;
}

const FlexContainer = (props: FlexContainerProps): JSX.Element => <StyledFlex {...props} />;

FlexContainer.defaultProps = {
  width: undefined,
  height: '100%',
  padding: '0',
  className: '',
  direction: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: '0 auto',
};

export default FlexContainer;
