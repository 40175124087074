import React from 'react';
import { useIntl } from 'react-intl';

import { formatDate } from '@src/library/date';

import { LegalPageProps } from './types';
import { StyledLegalPage, StyledDate } from './styles';

/**
 * Following translations are just to avoid requesting to the content team to push
 * new keys/translations.
 */

const DEFAULT_TRANSLATION = 'Updated on';

const UPDATED_AT_TRANSLATIONS = Object.freeze({
  'en-us': DEFAULT_TRANSLATION,
  'es-mx': 'Última actualización en',
  'pt-br': 'Última Atualização em',
});

const LegalPageRenderer = (props: LegalPageProps): JSX.Element => {
  const { locale: countryCode } = useIntl();
  const publishedDate = formatDate(props.publishedAt, countryCode);

  return (
    <StyledLegalPage>
      <StyledDate>
        {UPDATED_AT_TRANSLATIONS[countryCode] || DEFAULT_TRANSLATION} {publishedDate}
      </StyledDate>
      {props.content}
    </StyledLegalPage>
  );
};

export default LegalPageRenderer;
