import { Button, spacing, Typography } from '@bitsoex/react-design-system';

import CtfImage from '@vendors/contentful/cms/renderers/CtfImage';
import { ICtfImage } from '@vendors/contentful/cms/renderers/CtfImage/types';

import SectionTag from '../SectionTag';
import { StyledDeals, StyledImage } from './CardDeals.styles';

const dealsImageProps: ICtfImage['customProps'] = {
  default: {
    width: 52,
    height: 52,
  },
};

const CardDeals = ({ tagIcon, tagText, heading, subheading, cta, image }): JSX.Element => (
  <StyledDeals>
    <div>
      {tagIcon && tagText ? <SectionTag {...{ tagIcon, tagText }} className="cardTag" /> : null}
      <Typography
        variant="titleLarge"
        color="onSurfaceHigh"
        margin={{
          bottom: spacing.size05,
          top: spacing.size05,
        }}
        tag="h2"
      >
        {heading}
      </Typography>
      <Typography
        variant="bodyLarge"
        color="onSurfaceLow"
        margin={{
          bottom: spacing.size07,
        }}
        tag="h2"
      >
        {subheading}
      </Typography>
      <Button label={cta.props.props.text} onClick={() => window.open(cta.props.props.url, cta.props.props.target)} />
    </div>
    <div>
      <StyledImage>
        <CtfImage {...image} customProps={dealsImageProps} />
      </StyledImage>
    </div>
  </StyledDeals>
);

export default CardDeals;
