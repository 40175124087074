// Libraries
import { breakpoints } from '@bitsoex/react-design-system';
import styled from 'styled-components';

export const StyledGenericLandingPage = styled.section`
  margin: auto;
  max-width: 100vw;
  @media only screen and (min-width: ${breakpoints.tabletSmall}) {
    max-width: ${breakpoints.desktopLarge};
  }

  @media only screen and (min-width: ${breakpoints.desktopMedium}) {
    margin: auto 32px;
    padding: 0 32px;
  }
`;

export const StyledGenericLandingPageWrapper = styled.div`
  @media only screen and (min-width: ${breakpoints.desktopMedium}) {
    display: flex;
    justify-content: center;
  }
`;
