import styled, { css } from 'styled-components';

import { universe, rgba } from '@bitsoex/react-design-system';
import { FullStyled } from '@legacy/moon/Layout/Container';

export const Wrapper = styled.section`
  background-color: ${p => p.theme.colors?.backgroundDefault};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SectionContainer = styled(FullStyled)<{ $gradients?: number }>`
  ${({ $gradients, theme }) => {
    const buyGradient = rgba(theme?.colors?.buyDefault || universe.borealis50, 0.1);
    switch ($gradients) {
      case 1:
        return css`
          background: linear-gradient(135deg, transparent 70%, ${buyGradient} 100%);
        `;
      case 2:
        return css`
          background: linear-gradient(225deg, transparent 70%, ${buyGradient} 100%),
            linear-gradient(45deg, transparent 70%, ${buyGradient} 100%);
        `;
      default:
        break;
    }
  }}
  border-top: 1px solid ${rgba(universe.moonlight0, 0.1)};
  margin: auto;
  width: 100%;
`;
