import { Link, Typography, spacing } from '@bitsoex/react-design-system';

const PressroomContentTable = (props): JSX.Element => (
  <ol>
    <Typography variant="subheading" margin={{ bottom: spacing.size03, top: spacing.size03 }}>
      {props.title}
    </Typography>
    {props.links.map((link, index) => (
      <li key={index}>
        <Link label={link.props.props.text} type="anchor" href={link.props.props.url} />
      </li>
    ))}
  </ol>
);

export default PressroomContentTable;
